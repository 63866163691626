@import url(./variable.scss);
@import url(./iconfont.css);

html,
body {
  margin: 0;
  font-size: 16px;
}

body {
  font-family: var(--baseFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgColor);
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.npt-wrapper {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.npt-alone {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100vw;
  min-height: 100vh;
}

.npt-notFound {
  display: grid;
  grid-gap: var(--space03);
  justify-items: center;
  align-content: center;
  font-size: 1.5rem;
  color: var(--textColor);
  > p {
    &:nth-child(2) {
      font-size: 1rem;
      color: var(--colorGray03);
    }
    &:last-child {
      margin-top: var(--space03);
    }
  }

  a {
    font-size: 1rem;
    color: var(--primaryColor);
    text-decoration: none;
  }
}

.npt-dropdown-item {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  padding: var(--space01) var(--space02);
  font-size: 1rem;
  color: var(--colorGray08);
  cursor: pointer;
  span:nth-child(2) {
    margin-left: var(--space02);
  }
  // span:nth-child(3) {
  //   margin-left: var(--space01);
  //   font-size: .75rem;
  //   color: var(--primaryFocusColor);
  // }

  &:hover {
    background-color: var(--colorGray01);
  }

  &:first-child {
    border-radius: var(--borderRadius01) var(--borderRadius01) 0 0;
  }

  &:last-child {
    border-radius: 0 0 var(--borderRadius01) var(--borderRadius01);
  }

  .icon-npt {
    font-size: 1.25rem;
    line-height: 1;
  }
}

.result-streaming {
  position: relative;
  margin-left: 0.25rem;
  font-weight: 100;
  vertical-align: baseline;
  animation: blink 1s steps(5, start) infinite;
  // &::before {
  //   content: "▋";
  //   animation: blink 1s steps(5, start) infinite;
  // }
}

@keyframes blink {
  to {
    visibility: hidden
  }
}

::selection {
  color: #fff;
  background-color: var(--colorGray05);
}

::-webkit-input-placeholder {
  color: var(--colorGray02);
}
