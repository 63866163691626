@font-face {
  font-family: "icon-npt"; /* Project id 3967957 */
  src: url('//at.alicdn.com/t/c/font_3967957_obrc3ogh2q.woff2?t=1684726998577') format('woff2'),
       url('//at.alicdn.com/t/c/font_3967957_obrc3ogh2q.woff?t=1684726998577') format('woff'),
       url('//at.alicdn.com/t/c/font_3967957_obrc3ogh2q.ttf?t=1684726998577') format('truetype'),
       url('//at.alicdn.com/t/c/font_3967957_obrc3ogh2q.svg?t=1684726998577#icon-npt') format('svg');
}

.icon-npt {
  font-family: "icon-npt" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-npt-stable:before {
  content: "\e682";
}

.icon-npt-claude:before {
  content: "\e681";
}

.icon-npt-sort:before {
  content: "\e60b";
}

.icon-npt-search:before {
  content: "\e60e";
}

.icon-npt-market:before {
  content: "\e607";
}

.icon-npt-Aa:before {
  content: "\e637";
}

.icon-npt-download:before {
  content: "\e6b2";
}

.icon-npt-bing:before {
  content: "\e69a";
}

.icon-npt-send:before {
  content: "\e60d";
}

.icon-npt-leru:before {
  content: "\e680";
}

.icon-npt-share-fill:before {
  content: "\e611";
}

.icon-npt-share:before {
  content: "\e79d";
}

.icon-npt-email:before {
  content: "\e61b";
}

.icon-npt-tip:before {
  content: "\e72b";
}

.icon-npt-dot:before {
  content: "\ec1e";
}

.icon-npt-ok:before {
  content: "\ebe6";
}

.icon-npt-add-fill:before {
  content: "\e6e0";
}

.icon-npt-minus-fill:before {
  content: "\e844";
}

.icon-npt-close:before {
  content: "\e668";
}

.icon-npt-arrow:before {
  content: "\e79c";
}

.icon-npt-eye:before {
  content: "\e78f";
}

.icon-npt-eye-close:before {
  content: "\e8ff";
}

.icon-npt-info:before {
  content: "\e8e8";
}

.icon-npt-vedio:before {
  content: "\e60a";
}

.icon-npt-choice:before {
  content: "\e601";
}

.icon-npt-text:before {
  content: "\e959";
}

.icon-npt-audio:before {
  content: "\e897";
}

.icon-npt-down:before {
  content: "\e686";
}

.icon-npt-unchecked:before {
  content: "\e7ae";
}

.icon-npt-checked:before {
  content: "\e618";
}

.icon-npt-empty:before {
  content: "\e73e";
}

.icon-npt-profile:before {
  content: "\e717";
}

.icon-npt-menu:before {
  content: "\e962";
}

.icon-npt-log:before {
  content: "\e719";
}

.icon-npt-credit:before {
  content: "\e67e";
}

.icon-npt-chat:before {
  content: "\e67f";
}

.icon-npt-logout:before {
  content: "\e609";
}

.icon-npt-add:before {
  content: "\e678";
}

.icon-npt-delete:before {
  content: "\e679";
}

.icon-npt-save:before {
  content: "\e67a";
}

.icon-npt-edit:before {
  content: "\e67b";
}

.icon-npt-copy:before {
  content: "\e67c";
}

.icon-npt-set:before {
  content: "\e67d";
}

.icon-npt-open:before {
  content: "\e6be";
}

.icon-npt-account:before {
  content: "\e636";
}

.icon-npt-help:before {
  content: "\e87a";
}

.icon-npt-library:before {
  content: "\e677";
}

.icon-npt-publish:before {
  content: "\e675";
}

.icon-npt-unpublish:before {
  content: "\e676";
}

.icon-npt-loading:before {
  content: "\e891";
}
