.npt-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  color: var(--primaryColor);
  line-height: 1;
  transform: translate(-50%, -50%);
  animation: rotate 1.2s infinite linear;
  z-index: 999;
  .icon-npt-loading {
    font-size: inherit;
  }
}
@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
