$prefix: npt;
// 默认样式变量
:root {
  --colorWhite: #FFF;
  --colorDanger: #DB3214;
  --colorDangerHover: #BF2C12;
  --colorWarn: #db7900;
  --colorGray00: #F5F5F5;
  --colorGray01_m: #EBEBEB;
  --colorGray01_m_opa: #EBEBEB40;
  --colorGray01: #D9D9D9;
  --colorGray02: #B3B3B3;
  --colorGray03: #8C8C8C;
  --colorGray04: #595959;
  --colorGray05: #333333;
  --colorGray06_s: #262626;
  --colorGray06: #1F1F1F;
  --colorGray07: #171717;
  --colorGray08: #141414;
  --colorGray08_opa: #14141426;
  --colorGray08_opa_m: #14141459;
  --colorBlack: #000;
  --colorBlack_opa: #00000080;
  --colorHover: #170502;
  --primaryColor: #00ffbb;
  --primaryFocusColor: #00e0a5;
  --primaryColor_opa: #00ffbb26;
  --primaryColor_opa_s: #00ffbb0d;
  --bgColor: var(--colorBlack);
  --bgWrapperColor: var(--colorGray08);
  --colorShadow01: 0 0.375rem 0.5rem 0 rgba(0,0,0,.03),0 0.0625rem 0.125rem 0 rgba(0,0,0,.3);
  --colorShadow02: 0 0.125rem 0.375rem 0 rgba(0,0,0,.1),0 0.0625rem 0.625rem 0 rgba(0,0,0,.08),0 0.125rem 1.25rem -0.0625rem rgba(0,0,0,.015);
  --textColor: var(--colorWhite);
  --titleColor: var(--colorGray01);
  --labelColor: var(--colorGray03);
  --buttonColor: var(--primaryColor);
  --buttonHoverColor: var(--primaryFocusColor);
  --borderColor: #292929;
  --space10: 6rem;
  --space09: 5rem;
  --space08: 4.5rem;
  --space07: 4rem;
  --space06: 3rem;
  --space05: 2rem;
  --space05_s: 1.875rem;
  --space04: 1.5rem;
  --space04_ne: -1.5rem;
  --space04_s: 1.4rem;
  --space03_m: 1.25rem;
  --space03: 1rem;
  --space02: 0.75rem;
  --space01: .5rem;
  --space00: .25rem;
  --baseFamily: "Manrope",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightBlack: 800;
  --borderRadius00: 0.375rem;
  --borderRadius01: 0.75rem;
  --borderRadius02: 1.25rem;
  --borderRadius03: 3.75rem;
  --borderRadius04: 6.25rem;
  --scale: calc(vw / 29rem);
}
// .light-theme {}
